import { ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppLogo } from "../../components/layout/AppLogo";
import Button from "../../components/ui/button";
import { useClientStore } from "../../store/clients.store";
import SpinnerLoader from "../../components/spinner-loader";
import { useAuthStore } from "../../store/auth.store";
import { BASE_API_URL } from "../../constants";

function AccountsPage() {
  const [isLoading, setIsLoading] = useState(true);

  const loadClients = useClientStore(state => state.loadClients);
  const setClients = useClientStore(state => state.setClients);
  const clients = useClientStore(state => state.clients);
  const loading = useClientStore(state => state.loading);
  const authUser = useAuthStore(state => state.authUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (authUser?.admin) loadClients();
    else setClients(authUser?.clientUserRoles ?? []);
  }, [authUser?.admin]);

  useEffect(() => {
    if (clients?.length === 1) {
      navigate(`/app/accounts/${clients[0]?.clientId}`);
    } else {
      setIsLoading(false);
    }
  }, [clients?.length]);

  return (
    <div className="w-full flex flex-col py-8 px-12 justify-center items-center min-h-[80vh] rounded-xl  max-w-[500px] ">
      <AppLogo />
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mt-12 "}>{authUser?.admin ? "Clients" : "Accounts"}</h1>
      <p className="my-4 font-[380] text-[14px]">Choose {authUser?.admin ? "client" : "account"} to continue</p>

      <div className={"flex-1 flex flex-col w-full"}>

        <div className={"divide-y-2"}>
          {
            (loading || isLoading) ? <SpinnerLoader />
              : clients?.length < 1 ?
                <div className={"flex items-center justify-center py-5"}>
                  <p className={"text-black/60 font-bold"}>No {authUser?.admin ? "Client" : "Account"} Found</p>
                </div>
                :
                clients?.map(client => (
                  <Link
                    key={client?.clientId}
                    to={`/app/accounts/${client?.clientId}`}
                    onClick={() => {
                      localStorage.setItem("clientId", client?.clientId + "");
                    }}
                    className="flex items-center justify-between w-full gap-4 p-2 py-4 cursor-pointer hover:bg-grayish">
                    <div className="flex items-center justify-between gap-4">
                      <div className="h-12 shrink-0 w-12 rounded-full bg-[#a48080] ">
                        <img alt={client?.companyName} height={56} width={56} src={`${BASE_API_URL}image/file?filePath=${encodeURI(client.avatar)}&type=circle&width=112&height=112`} className="bg-[#D9D9D9] rounded-full h-full w-full"></img>
                      </div>
                      <p className="text-[20px] font-bold">{client?.companyName}</p>
                    </div>
                    <ChevronRight className="cursor-pointer text-[#a48080]" />
                  </Link>

                ))
          }
        </div>

        <Button variant={"ghost"} className="mt-auto">
          Log out
        </Button>
      </div>
    </div>
  );
}

export default AccountsPage;