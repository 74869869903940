import React, { useEffect, useState } from "react";
import {
  checkDocumentState,
  closeDocX,
  DocumentState,
  DocumentType,
  getAllCustomProperties,
  getLoadedDocumentType,
  setDocumentLoaded
} from "../../lib/document-utilities";
import { useAuthStore } from "../store/auth.store";
import { Loader } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTemplateStore } from "../store/templates.store";
import Button from "./ui/button";
import { fetchStorageFile2 } from "../services/templates.service";
import { useWorkspaceStore } from "../store/workspace.store";
import { downloadContract } from "../services/workspace.service";

interface SessionCheckerProps {
  children: React.ReactNode;
}
export function SessionChecker({ children }: SessionCheckerProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [docType, setDocType] = useState<DocumentType | null>(null);
  const [isVerifyingDocument, setIsVerifyingDocument] = useState(false);
  const authUser = useAuthStore(state => state.authUser);
  const setDocumentOpened = useTemplateStore(state => state.setDocumentOpened);
  const checkPreviousSession = useTemplateStore(state => state.checkPreviousSession);
  const loadClientTemplate = useTemplateStore(state => state.loadClientTemplate);
  const loadWorkspace = useWorkspaceStore(state => state.loadWorkspace);

  const navigate = useNavigate();
  const params = useParams();

  const clientId = params.clientId ?? localStorage.getItem("clientId");

  console.log({ params });
  console.log({ clientId });

  const loadDocumentPreviousState = async () => {
    console.log("Loading document previous state...");

    try {
      setError('');

      const docState = await checkDocumentState();
      const docType = await getLoadedDocumentType();
      const properties = await getAllCustomProperties();

      setDocType(docType);
      setDocumentOpened(false);

      console.log("Document state: ", docState);
      console.log("Document properties: ", properties);


      if (docState === DocumentState.VERIFY) {
        setLoading(false);
        setIsVerifyingDocument(true);

        if (docType === DocumentType.CONTRACT) {
          if (!properties?.contractId || !properties?.contractVersion) {
            setIsVerifyingDocument(false);
            setError("Error: document expired");
            return;
          }
          const contract = await loadWorkspace(Number(clientId), Number(properties.contractId), Number(properties.contractVersion));

          if (!contract) {
            setIsVerifyingDocument(false);
            setError("Error: document expired");
            return;
          }

          const fileResponse = await downloadContract(Number(clientId), Number(properties.contractId), Number(properties.contractVersion));

          if (!fileResponse.success) {
            setIsVerifyingDocument(false);
            setError("Error: document expired");
            return;
          }

          setDocumentOpened(true);
          setDocumentLoaded(properties);
          navigate(`/app/accounts/${clientId}/workspaces/${properties.contractId}?version=${properties.contractVersion}`);
        } else if (docType === DocumentType.TEMPLATE) {
          if (!properties?.templateId) {
            setError("Error: document expired");
            setIsVerifyingDocument(false);
            return;
          }

          const template = await loadClientTemplate(clientId, properties?.templateId);

          if (!template) {
            setError("Error: document expired");
            setIsVerifyingDocument(false);
            return;
          }

          let { fileUrl } = template;

          const fileResponse = await fetchStorageFile2(fileUrl);

          if (!fileResponse.success) {
            setError("Error: document expired");
            setIsVerifyingDocument(false);
            return;
          }

          setDocumentOpened(true);
          setDocumentLoaded(properties);
          // setIsVerifyingDocument(false);

          navigate(`/app/accounts/${clientId}/templates/${properties?.templateId}`);
        } else {
          setIsVerifyingDocument(false);
          setError("Error: document expired");
        }

      } else if (docState === DocumentState.BLANK) {
        setLoading(false);
      }
      else if (docState === DocumentState.DIRTY) {
        setLoading(false);
        setError("Incompatible document");
      }
      else if (docState === DocumentState.LOADED) {
        setDocumentOpened(true);
        setLoading(false);

        if (docType === DocumentType.CONTRACT) {
          if (properties?.contractId && properties?.contractVersion) {
            console.log("Navigating to contract...");
            
            navigate(`/app/accounts/${clientId}/workspaces/${properties.contractId}?version=${properties.contractVersion}`);
          } else {
            navigate(`/app/accounts/${clientId}/workspaces`);
          }
        } else if (docType === DocumentType.TEMPLATE) {
          if (properties?.templateId) {
            navigate(`/app/accounts/${clientId}/templates/${properties?.templateId}`);
          }
        } else {
          setLoading(false);
          setError("Incompatible document type.");
        }
      }
    }
    catch (error: any) {
      console.log("Error...", error.message);
    }
    finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (authUser?.userId && checkPreviousSession) {
      loadDocumentPreviousState();
    } else {
      setLoading(false);
    }
  }, [authUser?.userId, checkPreviousSession]);

  if (loading || isVerifyingDocument) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Loader className='w-8 h-8 animate-spin' />
        {
          isVerifyingDocument && <span className="mt-2 text-gray-700">Verifying document...</span>
        }
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen ">
        <p className={"text-red-400"}>{error || "An error occurred! Please try again"}</p>
        <Button onClick={() => {
          closeDocX().then(() => {
            setError("");
            setLoading(false);
            if (docType === DocumentType.CONTRACT) {
              navigate(clientId ? `/app/accounts/${clientId}/workspaces` : "/app/accounts");
            } else {
              navigate(clientId ? `/app/accounts/${clientId}/templates` : "/app/accounts");
            }
          });
        }}
          className="px-6 mt-4">Close document</Button>
      </div>
    );
  }

  return (
    <>
      {children}
    </>
  );
}