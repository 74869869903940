import React, { useEffect } from "react";
import { ChevronRight, Plus } from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AppHeader } from "../../components/layout/app-header";
import SpinnerLoader from "../../components/spinner-loader";
import { useTemplateStore } from "../../store/templates.store";

function TemplatesPage() {
  const loadingTemplates = useTemplateStore(state=>state.loading);
  const templates = useTemplateStore(state=>state.templates);
  const loadClientTemplates = useTemplateStore(state=>state.loadClientTemplates);
  const setSelectedTemplate = useTemplateStore(state=>state.setSelectedTemplate);
  const setCheckPreviousSession = useTemplateStore(state=>state.setCheckPreviousSession);

  const params = useParams();
  const navigate = useNavigate();

  const handleSelectTemplate = (template: Template)=>{
    setCheckPreviousSession(false);
    setSelectedTemplate(template);
    localStorage.setItem("clientId", params?.clientId);
    navigate(`/app/accounts/${params?.clientId}/templates/${template.templateId}`)
  }

  useEffect(() => {
    if(params?.clientId) loadClientTemplates(Number(params?.clientId));
  }, [params?.clientId]);

  return (
    <div className="w-full p-4 max-w-[500px] flex flex-col h-screen">
      <AppHeader web_url={"/app/"} />

      <div className="flex items-center w-full p-1 h-[42px] mb-5 bg-[#EDEDED] rounded-full mt-3">
        <Link
          to={`/app/accounts/${params?.clientId}/workspaces`}
          className={"font-[670] text-primary  h-full  rounded-full flex-1 flex items-center justify-center "}
        >
          Workspace
        </Link>
        <Link
          to={`/app/accounts/${params?.clientId}/templates`}
          className={"font-[670] text-primary bg-white h-full rounded-full flex-1 flex items-center justify-center "}
        >
          Templates
        </Link>
      </div>

      <input
        placeholder="Search"
        className="input w-full border-4 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                      text-primary font-[380] text-[15px] leading-[24px] placeholder:text-[#717171]
                      placeholder:opacity-100 placeholder:font-[570]
                    "
      />

      <div className={"flex-1 overflow-y-auto mt-4 scrollbar-sm scroll-hidden"}>

        {
          loadingTemplates ? <div className={"hgdghdgfdgh"}> <SpinnerLoader /> </div> :
            <>
              {
                templates?.length < 1 ?
                  <div className={"flex items-center justify-center py-5"}>
                    <p className={"text-black/60 font-bold"}>No contract Found</p>
                  </div>
                  : templates?.map((template, idx) => (
                    <React.Fragment key={idx}>
                      <div onClick={()=>handleSelectTemplate(template)} className="flex items-center px-1.5 hover:bg-grayish cursor-pointer justify-between w-full max-w-[800px] gap-4 mx-auto py-4">
                        <div className="flex items-center gap-4">
                            <span>
                              <svg width="30" height="30" viewBox="0 0 18 20" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17.0306 5.71938L11.7806 0.469375C11.7109 0.399749 11.6282 0.344539 11.5371 0.306898C11.4461 0.269257 11.3485 0.249923 11.25 0.25H2.25C1.85218 0.25 1.47064 0.408035 1.18934 0.68934C0.908035 0.970645 0.75 1.35218 0.75 1.75V18.25C0.75 18.6478 0.908035 19.0294 1.18934 19.3107C1.47064 19.592 1.85218 19.75 2.25 19.75H15.75C16.1478 19.75 16.5294 19.592 16.8107 19.3107C17.092 19.0294 17.25 18.6478 17.25 18.25V6.25C17.2501 6.15148 17.2307 6.05391 17.1931 5.96286C17.1555 5.87182 17.1003 5.78908 17.0306 5.71938ZM11.25 6.25V2.125L15.375 6.25H11.25Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            <h4 className="font-bold">{template.name}</h4>
                        </div>
                        <ChevronRight className="cursor-pointer text-[#a48080]" />
                      </div>

                      {
                        idx < templates?.length - 1 && <hr />
                      }
                    </React.Fragment>
                  ))
              }
            </>
        }
      </div>

      {/*<Link to={`/app/accounts/${params?.clientId}/select-customer`} className={"mt-6 px-1.5"}>*/}
        {/* <Button>
          <Plus className="" />
          <span className="font-extrabold text-white text-[18px] ml-5">Create a template </span>
        </Button> */}
      {/*</Link>*/}
    </div>
  );
}

export default TemplatesPage;