import React from "react";
import Button from "../../../../components/ui/button";
import { TemplateOptionType } from "../../../../../lib/template-options-types";
import { cn } from "../../../../utils";
import { TemplateTools } from "../../../../../lib/template-utilities";

interface ContractAmendmentsProps {
  workspace: Workspace,
  updateSelectedOption: (option: Record<string, any>) => void;
  updateCurrentView: (view: string) => void;
}
const ContractAmendments = ({ workspace, updateSelectedOption, updateCurrentView }: ContractAmendmentsProps) => {

  return (
    <div className={"h-full flex-1 mt-4 flex flex-col"}>
      {
        workspace?.amendments.length <= 0 ?
          <div className={"flex-1 flex items-center justify-center"}>
            <p className={"font-[380] text-[16px] leading-[24px] text-center text-[#666666] "}>No amendments found</p>
          </div>
          :
          <div className={"flex-1 w-full py-4 space-y-3 overflow-y-auto"}>
            {
              workspace?.amendments?.map((amendment, index) => (
                <AmendMentItem key={index} amendment={amendment} contract={workspace} onClick={option => {
                  updateSelectedOption(option);
                  updateCurrentView("option-edit")
                }} />
              ))
            }
          </div>
      }

      <Button className={"save hidden"}>Save</Button>
    </div>
  );
};

interface AmendmentItemProps {
  amendment: Amendment;
  contract: Workspace;
  onClick?: (option: Record<string, any>) => void;
}

const AmendMentItem = ({ amendment, contract, onClick=()=>{} }: AmendmentItemProps) => {
  const option = contract.template.options[amendment.optionId];

  return (
    <div
      onClick={() => {
        onClick({...option, optionId: amendment.optionId});
      }}
      className={cn("w-full rounded-[8px] border-[1px] border-[#00000014] hover:bg-grayish cursor-pointer p-[16px] ", {
                        "border-[#0F4CEAA3] border-[4px]": false
                      })}>
      <div className={"flex items-center gap-2"}>
        {option.type === TemplateOptionType.TEXT && textSVG}
        {option.type === TemplateOptionType.RANGE && rangeSvg}

        <div>
          <h2 className={"font-[570] text-[16px] leading-[20px] text-[#000000]"}>{amendment.optionId}</h2>
          {amendment?.requestedByUser?.firstName && (
            <p className={"text-[12px] leading-[16px] text-[#717171] font-[380]"}>Amended by <span
              className={"font-[570] text-[#000000]"}>{amendment?.requestedByUser?.firstName}</span></p>
          )}
          {amendment?.reviewedByUser?.firstName && (
            <div className={"tooltip -mt-2 p-0"} data-tip={amendment?.reviewNote}>
              <p
                className={`text-[12px] leading-[16px] ${amendment?.status === "REJECTED" ? "text-red-400" : amendment?.status === "APPROVED" ? "text-green-500" : "text-[#717171]"} font-[380]`}>
                {amendment?.status === "APPROVED" ? "Approved" : amendment?.status === "REJECTED" ? "Rejected" : "Reviewed"} {" "}
                by <span
                className={`font-[570] ${amendment?.status === "REJECTED" ? "text-red-400" : amendment?.status === "APPROVED" ? "text-green-500" : "text-[#000000]"}`}>{amendment?.reviewedByUser?.firstName}</span>
              </p>
            </div>
          )}
          {option.type === TemplateOptionType.TEXT && option?.values[amendment.valueId]?.needsApproval && amendment?.status === "PENDING" && (
            <p className={"font-[570] text-[12px] text-[#ED4B4B] items-center leading-[16px] flex gap-1.5"}>
              <svg width="12" className={"shrink-0"} height="12" viewBox="0 0 12 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_816_1711)">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 1.99999C6.41421 1.99999 6.75 2.33578 6.75 2.74999V6.3C6.75 6.71421 6.41421 7.05 6 7.05C5.58579 7.05 5.25 6.71421 5.25 6.3V2.74999C5.25 2.33578 5.58579 1.99999 6 1.99999ZM6.90156 8.99998C6.90156 9.49703 6.49862 9.89997 6.00156 9.89997C5.50451 9.89997 5.10156 9.49703 5.10156 8.99998C5.10156 8.50292 5.50451 8.09998 6.00156 8.09998C6.49862 8.09998 6.90156 8.50292 6.90156 8.99998Z"
                        fill="#ED4B4B" />
                </g>
                <defs>
                  <clipPath id="clip0_816_1711">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>Requires approval</span>
            </p>
          )}
        </div>

        <button
          onClick={(e) => {
            console.log("EYE Clicked");
            e.preventDefault();
            e.stopPropagation();
            TemplateTools.scrollToTag(amendment.optionId);
          }}
          className={"shrink-0 ml-auto"}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_816_1713)">
              <path
                d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                fill="#666666" />
            </g>
            <defs>
              <clipPath id="clip0_816_1713">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>


      </div>

      <div className={"my-2 h-[1px] bg-[#0000001F]"} />

      <div className={"grid grid-cols-2 gap-2"}>
        <div className={""}>
          <h2 className={"font-[380] text-[12px] leading-[16px] text-[#717171]"}>From</h2>
          <p className={cn("font-[380] text-[14px] leading-[20px]", {
            "text-[#666666]": option.type === TemplateOptionType.TEXT,
            "text-[#000000]": option.type === TemplateOptionType.RANGE
          })}>

            {option.type === TemplateOptionType.TEXT && option.values?.[amendment.valueId]?.text}
            {option.type === TemplateOptionType.RANGE && option.value}
          </p>

        </div>
        <div className={""}>
          <h2 className={"font-[380] text-[12px] leading-[16px] text-[#717171]"}>To</h2>
          <p className={"font-[570] leading-[20px] text-[14px] text-[#000000]"}>
            {amendment.value}
          </p>
        </div>
      </div>
    </div>
  );
};

const textSVG = (
  <svg className={"shrink-0"} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="5" fill="#F4F4F4" />
    <path
      d="M10.5002 5.16666H7.16683C6.43045 5.16666 5.8335 5.76361 5.8335 6.49999V13.5C5.8335 14.2364 6.43045 14.8333 7.16683 14.8333H12.8335C13.5699 14.8333 14.1668 14.2364 14.1668 13.5V8.83332M10.5002 5.16666V7.49999C10.5002 8.23637 11.0971 8.83332 11.8335 8.83332H14.1668M10.5002 5.16666L14.1668 8.83332"
      stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.8335 12.5H12.1668" stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.8335 10.5H9.50016" stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const rangeSvg = (
  <svg className={"shrink-0"} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.1665 4.16669L4.1665 3.16669V6.83335M4.1665 6.83335H3.1665M4.1665 6.83335H4.83317" stroke="#666666"
          strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.83317 9.5H7.77064C7.2835 9.5 7.01197 8.95727 7.27037 8.5716C7.30217 8.52407 7.34724 8.4874 7.39484 8.45573L8.59477 7.65733C8.64237 7.6256 8.6873 7.5888 8.71977 7.54173C9.0167 7.11187 8.71404 6.5 8.16824 6.5H7.1665"
      stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.1665 9.83331H12.1308C12.9537 9.83331 13.0998 11.0917 12.3399 11.3037C12.3031 11.314 12.2645 11.3181 12.2264 11.3194L11.8332 11.3333L12.2264 11.3472C12.2645 11.3485 12.3031 11.3526 12.3399 11.3629C13.0998 11.5749 12.9537 12.8333 12.1308 12.8333H11.1665"
      stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default ContractAmendments;