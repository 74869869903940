export let BASE_API_URL = "http://localhost/api/";

// Running in browser
if (typeof window === 'object') {
  BASE_API_URL = (window.location.host?.includes('localhost:3')) ? "http://localhost/api/" : window.location.origin + "/api/";
}

export const ClientUserAccessRoles = [
  "ADMINISTRATOR",
  "MEMBER",
];

export const UserStatuses = [
  "PENDING",
  "ACTIVE",
  "INACTIVE"
];

export const CustomerStatuses = [
  "ACTIVE",
  "INACTIVE"
];

export const ClientTemplateClearanceLevels = [
  "FULL",
  "LIMITED",
];

export const EntityStatuses = [
  "ACTIVE",
  "INACTIVE",
  "DELETED"
];

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


export const AUTO_REPLACE_OPTIONS = [
  {label:"10", value: '10'},
  {label:"20", value: '20'},
  {label:"30", value: '30'},
  {label:"40", value: '40'},
  {label:"50", value: '50'},
  {label:"100", value: '100'},
  {label:"200", value: '200'},
]