import { create } from "zustand";
import { fetchContract, fetchContracts } from "../services/workspace.service";
import ContractAmendments from "../pages/workspaces/contract/_components/contract-amendments";

type WorkspaceStore = {
  loading: boolean;
  workspaces: Workspace[];
  workspace: Workspace;
  selectedWorkspace: Workspace | null;
  selectedCustomer: Customer | null;
  selectedContact: CustomerContact | null;
  selectedBilling: CustomerBilling | null;
  selectedTemplate: Template | null;
  contractParameters: ContractParameters | null;

  setSelectedCustomer: (customer: Customer | null) => void;
  setSelectedContact: (contact: CustomerContact | null) => void;
  setSelectedBilling: (billing: CustomerBilling | null) => void;
  setSelectedTemplate: (template: Template | null) => void;
  setContractParameters: (parameters: ContractParameters | null) => void;

  resetAllContractFields: () => void;

  loadWorkspaces: (clientId: number, status: string) => Promise<void>;
  loadWorkspace: (clientId: number, contractId: number, contractVersion?: number, silentLoad?: boolean) => Promise<Workspace|null>;

  updateWorkspaceAmendment: (amendment: Amendment) => void;
  resetLoadingState: () => void;
};

export const useWorkspaceStore = create<WorkspaceStore>((set, get) => ({
  loading: true,
  workspaces: [],
  workspace: null,

  selectedWorkspace: null,
  selectedCustomer: null,
  selectedContact: null,
  selectedBilling: null,
  selectedTemplate: null,
  contractParameters: null,

  setSelectedCustomer: (customer: Customer | null) => set({ selectedCustomer: customer }),
  setSelectedContact: (contact: CustomerContact | null) => set({ selectedContact: contact }),
  setSelectedBilling: (billing: CustomerBilling | null) => set({ selectedBilling: billing }),
  setSelectedTemplate: (template: Template | null) => set({ selectedTemplate: template }),
  setContractParameters: (parameters: ContractParameters | null) => set({ contractParameters: parameters }),
  resetAllContractFields: () => {
    set({
      selectedCustomer: null,
      selectedContact: null,
      selectedBilling: null,
      selectedTemplate: null,
      contractParameters: null
    });
  },

  loadWorkspaces: async (clientId: number, status: string) => {
    set({ loading: true, workspaces: [] });
    const response = await fetchContracts(clientId, status);
    if (response.success) set({ workspaces: response?.data?.contracts ?? [] });
    set({ loading: false });

    return response?.data?.contracts ?? [];
  },

  loadWorkspace: async (clientId: number, contractId: number, contractVersion?: number, silentLoad= false) => {
    set({ loading: !silentLoad, workspaces: [] });
    const response = await fetchContract(clientId, contractId, contractVersion);
    if (response.success) set({ workspace: response?.data?.contract ?? null });
    set({ loading: false });

    return {...response?.data?.contract, autocomplete: response?.data?.autocomplete} ?? null;
  },

  updateWorkspaceAmendment: async (amendment: Amendment) => {
    const workspace = get().workspace;
    if(!workspace) return;

    set({ workspace: {...workspace, amendments: [...workspace.amendments, amendment]} });
  },

  resetLoadingState: () => set({ loading: false})
}));